<template>
  <div class="v-input">
    <amount-field
      :label="label"
      :currency="this.$store.getters.currentUser.currency"
      :amount.sync="computedAmount"
      :empty-value="emptyValue"
      :dense="dense"
      :rules="rules"
      :disabled="disabled"
      :readonly="readonly"
      :append-icon="appendIcon"
      :prepend-inner-icon="prependInnerIcon"
      :reverse="reverse"
    />
    <div
      class="ml-5 mt-2 d-flex flex-column"
      v-if="readonly"
    >
      <calendar-date
        class="text-body-2"
        :date.sync="computedStartDate"
      />
      <calendar-date
        class="text-body-2"
        :date.sync="computedEndDate"
      />
    </div>
    <div
      class="d-flex flex-column"
      v-else
    >
      <date-picker-menu
        flat
        solo
        v-model="computedStartDate"
      />
      <date-picker-menu
        class="mt-n10"
        flat
        solo
        v-model="computedEndDate"
      />
    </div>
  </div>
</template>

<script>

export default {
  components: {
    AmountField: () => import('@/components/amount-field'),
    CalendarDate: () => import('@/components/calendar-date'),
    DatePickerMenu: () => import('@/components/date-picker-menu')
  },
  data () {
    return {
      dataStartDate: null,
      dataEndDate: null,
      dataAmount: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  methods: {
    emitDocument () {
      if (this.startDate !== this.dataStartDate) {
        this.$emit('update:startDate', this.dataStartDate)
      }
      if (this.endDate !== this.dataEndDate) {
        this.$emit('update:endDate', this.dataEndDate)
      }
      if (this.amount !== this.dataAmount) {
        this.$emit('update:amount', this.dataAmount)
      }
    }
  },
  computed: {
    computedStartDate: {
      get () {
        return this.dataStartDate
      },
      set (v) {
        this.dataStartDate = v
        this.dataDocumentEmit()
      }
    },
    computedEndDate: {
      get () {
        return this.dataEndDate
      },
      set (v) {
        this.dataEndDate = v
        this.dataDocumentEmit()
      }
    },
    computedAmount: {
      get () {
        return this.dataAmount
      },
      set (v) {
        this.dataAmount = v
        this.dataDocumentEmit()
      }
    }
  },
  watch: {
    startDate: {
      handler (v) {
        this.dataStartDate = v
      },
      immediate: true
    },
    endDate: {
      handler (v) {
        this.dataEndDate = v
      },
      immediate: true
    },
    amount: {
      handler (v) {
        this.dataAmount = v
      },
      immediate: true
    }
  },
  props: {
    appendIcon: String,
    prependInnerIcon: String,
    currency: [Object, String],
    label: String,
    amount: Number,
    startDate: [Object, String],
    endDate: [Object, String],
    dense: Boolean,
    rules: Array,
    disabled: Boolean,
    readonly: Boolean,
    reverse: Boolean,
    emptyValue: {
      type: [String, Number],
      default: null
    }
  }
}
</script>

<style lang="stylus" scoped></style>
